import {
  OPERATORS,
  type AppliedFilter,
  type Filter,
  type FilterOperator,
  type FilterValue,
} from '@/components/table-filters/constants';
import { camelToSnake, ObjectEntries, ObjectKeys, raise } from '@/lib/utils';
import type {
  PaginationState,
  SortingState,
  Updater,
} from '@tanstack/react-table';
import { getCookie } from 'cookies-next';
import { cloneDeep, get, isEmpty, merge, omit } from 'lodash';
import {
  parseAsArrayOf,
  parseAsInteger,
  parseAsJson,
  parseAsString,
  useQueryState,
} from 'nuqs';
import { useCallback, useMemo } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import type { JsonFilter } from './types';
import { orderByToParams, parseJsonFilter, parseOrderToClient } from './utils';

type TableFilterProps = {
  isClient?: boolean;
  defaultPageSize?: number;
  tableId: string;
};

const cleanFilters = (filters: JsonFilter) => {
  let filterClone = cloneDeep(filters);
  ObjectEntries(filters).forEach(([filterKey, value]) => {
    if (value === null) {
      filterClone = omit(filterClone, filterKey);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ObjectEntries(value).forEach(([operatorKey, operatorValue], index, arr) => {
      if (operatorValue === null) {
        // If this is the only item in the list then we want to clear the filter key
        if (arr.length < 2 && index === 0) {
          filterClone = omit(filterClone, filterKey);
          return;
        }

        // otherwise we simply remove this specific key
        filterClone[filterKey] = omit(filterClone[filterKey], [operatorKey]);
      }
    });
  });

  // passing null instead of an empty object will make nuqs clear the filter param from the url
  if (isEmpty(filterClone)) {
    return null;
  }
  return filterClone;
};

export function useTableFilter({
  isClient = false,
  defaultPageSize = 25,
  tableId,
}: TableFilterProps) {
  if (isClient && !tableId) raise('Needs tableId if client filter');

  const organizationId = getCookie('organization_id');
  const [filter, setFilter] = useQueryState(
    'filter',
    parseAsJson<JsonFilter>()
  );

  const [sessionFilter, setSessionFilter] = useSessionStorage<{
    [organizationId: string]: JsonFilter;
  } | null>(tableId, {});

  const [lastUsedFilter, setLastUsedFilter] = useSessionStorage<{
    [key: string]: JsonFilter | null;
  }>(tableId, {});

  const currentFilter = useMemo(() => {
    if (isClient) return sessionFilter?.[organizationId ?? ''] ?? {};
    return filter ?? {};
  }, [filter, sessionFilter, isClient, organizationId]);

  const [search, setSearch] = useQueryState('_search', {
    ...parseAsString,
    clearOnDefault: true,
    defaultValue: '',
  });

  const [sessionSearch, setSessionSearch] = useSessionStorage(
    `${tableId}_search`,
    ''
  );

  const [page, setPage] = useQueryState('_page', {
    ...parseAsInteger,
    defaultValue: 0,
  });

  const [pageSize, setPageSize] = useQueryState('_page_size', {
    ...parseAsInteger,
    defaultValue: defaultPageSize ?? 25,
  });
  const [sessionPage, setSessionPage] = useSessionStorage(`${tableId}_page`, 0);
  const [sessionPageSize, setSessionPageSize] = useSessionStorage(
    `${tableId}_page_size`,
    defaultPageSize ?? 25
  );

  const [orderBy, setOrderBy] = useQueryState('_order_by', {
    ...parseAsArrayOf(parseAsString),
    defaultValue: [],
    clearOnDefault: true,
  });

  const { parsedSorting, backendReadyParsedSorting } = useMemo(() => {
    const parsed = parseOrderToClient(orderBy ?? []);
    return {
      parsedSorting: parsed,
      backendReadyParsedSorting: parsed.map(
        (p) => `${p.desc ? '-' : ''}${camelToSnake(p.id)}`
      ),
    };
  }, [orderBy]);

  /**
 * @description
 * This combines input filters with the url/session filters 
 * @param name - combineWith: the filters to be merged with state filters.

 */
  const combineFilters = useCallback(
    (combineWith: JsonFilter) => {
      const filterCopy = cloneDeep(currentFilter);

      const merged = merge(filterCopy, combineWith);

      return merged;
    },
    [currentFilter]
  );

  /**
 * @description
 * This parses filters for sending to backend
 * @param name - filters: JsonFilter object.

 */
  const parseFilters = useCallback((filters: JsonFilter) => {
    return parseJsonFilter(filters);
  }, []);

  const toExcelDownloadFilterFormat = useCallback(
    (filters: JsonFilter): Record<string, string> => {
      const filterStr = parseFilters(filters);
      const filterParts = filterStr.split('&');

      const excelFormat: Record<string, string> = {};

      for (let i = 0; i < filterParts.length; i++) {
        const current = filterParts[i];
        const [filterKey, filterVal] = current.split('=');

        excelFormat[filterKey] = filterVal;
      }

      return excelFormat;
    },
    [parseFilters]
  );

  const getFilter = useCallback(
    <T extends FilterOperator | undefined = undefined>(
      filterExternalFieldName: Filter['externalFieldName'],
      operator?: T
    ) => {
      if (currentFilter === null) return;
      return get(
        currentFilter,
        operator
          ? [filterExternalFieldName, operator]
          : [filterExternalFieldName]
      ) as T extends FilterOperator
        ? (typeof currentFilter)[FilterOperator]
        : typeof currentFilter;
    },
    [currentFilter]
  );

  const getAllAppliedFilters = useCallback((): AppliedFilter[] => {
    if (!filter || ObjectKeys(filter ?? {}).length === 0) return [];
    return ObjectKeys(filter ?? {}).reduce<AppliedFilter[]>((acc, cur) => {
      const operatorVariants = ObjectKeys(filter[cur as string]).map(
        (operator) => {
          return {
            operator,
            value: filter[cur][operator] as string | number,
            externalFieldName: cur as string,
          };
        }
      );

      return [...acc, ...operatorVariants];
    }, []);
  }, [filter]);

  const getAppliedFilter = useCallback(
    (filter: Filter): AppliedFilter | undefined => {
      const allAppliedFilters = getAllAppliedFilters();

      return allAppliedFilters.find(
        (appliedFilter) =>
          appliedFilter.externalFieldName === filter.externalFieldName &&
          filter.operators.includes(appliedFilter.operator)
      );
    },
    [getAllAppliedFilters]
  );

  const setSearchAndClearPagination = useCallback(
    (searchValue: string) => {
      if (isClient) {
        setSessionSearch(searchValue);
        setSessionPage(0);
      } else {
        setSearch(searchValue);
        setPage(0);
      }
    },
    [isClient, setPage, setSearch, setSessionPage, setSessionSearch]
  );

  const setPagination = useCallback(
    (paginationData: Updater<PaginationState>) => {
      const oldPaginationData = {
        pageIndex: isClient ? sessionPage : page,
        pageSize: isClient ? sessionPageSize : pageSize,
      };
      if (typeof paginationData === 'function') {
        const newPaginationData = paginationData(oldPaginationData);
        const { pageSize: newPageSize, pageIndex: newPageIndex } =
          newPaginationData;

        if (isClient) {
          setSessionPage(newPageIndex);
          setSessionPageSize(newPageSize);
        } else {
          setPage(newPageIndex);
          setPageSize(newPageSize);
        }
      }
    },
    [
      isClient,
      page,
      pageSize,
      sessionPage,
      sessionPageSize,
      setPage,
      setPageSize,
      setSessionPage,
      setSessionPageSize,
    ]
  );

  const setSorting = useCallback(
    (sorting: Updater<SortingState>) => {
      if (typeof sorting === 'function') {
        const res = sorting(parsedSorting);
        const querySort = orderByToParams(res);
        setOrderBy(querySort);
      }
    },
    [parsedSorting, setOrderBy]
  );

  const setTableFilter = useCallback(
    (filter: JsonFilter | null) => {
      const cleanedFilter = filter ? cleanFilters(filter) : null;
      if (isClient) {
        setSessionFilter((prev) => ({
          ...prev,
          ...(organizationId && {
            [organizationId]: cleanedFilter ?? {},
          }),
        }));
        setSessionPage(0);
      } else {
        setFilter(cleanedFilter);
        setPage(0);
        if (organizationId) {
          setLastUsedFilter((prev) => ({
            ...prev,
            [organizationId]: cleanedFilter,
          }));
        }
      }
    },
    [
      isClient,
      setFilter,
      setLastUsedFilter,
      setPage,
      setSessionFilter,
      setSessionPage,
      organizationId,
    ]
  );

  const _internalSetFilterValue = useCallback(
    (
      externalFieldName: string,
      value: FilterValue,
      operator: FilterOperator
    ) => {
      setTableFilter({
        ...currentFilter,
        [externalFieldName]: {
          ...currentFilter?.[externalFieldName],
          [operator]: value,
        },
      });
    },
    [currentFilter, setTableFilter]
  );

  const setFilterValue = useCallback(
    <T extends Filter>(
      filter: T,
      newValue: FilterValue,
      operator?: T['operators'][number]
    ) => {
      _internalSetFilterValue(
        filter.externalFieldName,
        newValue,
        operator ?? filter.defaultOperator
      );
    },
    [_internalSetFilterValue]
  );

  const deleteAppliedFilterValue = useCallback(
    (filter: AppliedFilter) => {
      _internalSetFilterValue(filter.externalFieldName, null, filter.operator);
    },
    [_internalSetFilterValue]
  );

  const updateAppliedFilterOperator = useCallback(
    (filter: AppliedFilter, newOperator: FilterOperator) => {
      setTableFilter({
        ...currentFilter,
        [filter.externalFieldName]: {
          ...currentFilter?.[filter.externalFieldName],
          [filter.operator]: undefined,
          [newOperator]: OPERATORS.ISNULL.find((value) => value === newOperator)
            ? true
            : typeof filter.value === 'boolean'
              ? ''
              : filter.value,
        },
      });
    },
    [currentFilter, setTableFilter]
  );

  const updateAppliedFilterValue = useCallback(
    (filter: AppliedFilter, newValue: FilterValue) => {
      _internalSetFilterValue(
        filter.externalFieldName,
        newValue,
        filter.operator
      );
    },
    [_internalSetFilterValue]
  );

  const clearFilter = useCallback(
    (filter: Filter) => {
      const appliedFilter = get(currentFilter, filter.externalFieldName);

      if (filter) {
        setTableFilter({
          ...currentFilter,
          [filter.externalFieldName]: {
            ...omit(appliedFilter, filter.operators),
          },
        });
      }
    },
    [currentFilter, setTableFilter]
  );

  return {
    page: isClient ? sessionPage : page,
    pageSize: isClient ? sessionPageSize : pageSize,
    filter: currentFilter,
    getFilter,
    getAppliedFilter,
    getAllAppliedFilters,
    sorting: parsedSorting,
    sortingArray: orderBy,
    search: isClient ? sessionSearch : search,
    setSearch: setSearchAndClearPagination,
    setPage: isClient ? setSessionPage : setPage,
    setSorting,
    setPagination,
    setTableFilter,
    setFilterValue,
    updateAppliedFilterValue,
    updateAppliedFilterOperator,
    deleteAppliedFilterValue,
    clearFilter,
    combineFilters,
    parseFilters,
    lastUsedFilter,
    backendReadyParsedSorting,
    toExcelDownloadFilterFormat,
  };
}
